<template>
  <div>
    <div class="form-container">
      <div class="login-card">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">
              <span class="text-center">Dashboard Parametrización</span>
              <b-button type="is-light" style="margin-left: 28%;" size="is-small" tag="a" href="https://compensar-tableau-20240318-165827.azurewebsites.net" target="_blank">Reportería</b-button>
              </p>
          </header>
          <div class="card-content">
            <div class="align-container">
              <div>
                <form v-on:submit.prevent="login">
                  <b-field label="Correo electrónico:">
                    <b-input type="email" v-model="model.email" placeholder="Ingrese correo electrónico"
                             autocomplete="off"></b-input>
                  </b-field>

                  <b-field label="Contraseña:">
                    <b-input type="password"
                             v-model="model.password"
                             placeholder="Ingrese contraseña"
                             password-reveal>
                    </b-input>
                  </b-field>
                  <br>
                  <div v-if="catchInfo.error !== null">
                    <b-message type="is-danger" has-icon>
                      <span v-if="catchInfo.error === 'User not found'">No existe el usuario.</span>
                      <span v-else-if="catchInfo.error === 'Email or password incorrect.'">Error de autenticación</span>
                      <span v-else>{{ catchInfo.error }}</span>
                    </b-message>
                    <br>
                  </div>
                  <div class="is-flex is-flex-direction-row-reverse">
                    <b-button class="is-light" label="Ingresar" native-type="submit"
                              :disabled="model.email.length === 0 || model.password.length === 0"/>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <custom-footer/>
    </div>

    <b-loading
        :is-full-page="true"
        v-model="isLoading"
        :can-cancel="true"
    ></b-loading>
  </div>
</template>

<script>
import CustomFooter from "@/components/Layout/CustomFooter.vue";
import jsonConfig from '@/assets/config/jsonconfig.json'
import axios from 'axios';
import {mapMutations} from 'vuex'
import {HOST_API} from "../../assets/config/constants/GeneralConstants";

export default {
  name: "Login",
  components: {
    CustomFooter
  },
  data() {
    return {
      jsonConfig,
      isLoading: false,
      model: {
        email: '',
        password: '',
      },
      catchInfo: {
        error: null
      }
    }
  },
  methods: {
    ...mapMutations(['setTokenAuth', 'setUsuario', 'setExpireIh', 'setRoles', 'setPerfil']),
    login() {
      this.isLoading = true;
      this.catchInfo.error = null;
      var pass = Buffer(this.model.password);
      const data = {
        "Email": this.model.email,
        "Password": pass.toString('base64')
      };
      let url = `${jsonConfig.config.urlBase}/${jsonConfig.config.uriLogin}`;
      axios.post(url, data)
          .then(res => {
            this.setTokenAuth(res.data.token)
            this.setUsuario(data.Email)
            this.setExpireIh(res.data.expireIn)
            this.loadRoles(data.Email)
          })
          .catch(error => {
            this.catchInfo.error = error.response.data;
            this.cancelarLoading()
          })
    },
    loadRoles(email) {
      const options = {
        method: 'get',
        url: `${HOST_API}/profile/user`,
        headers: {
          Authorization: `Bearer ${this.$store.state.tokenAuth}`
        },
        params: {
          'email': email
        }
      };
      axios(options)
          .then((response) => {
            if (response.status === 200) {
              const data = response.data;
              this.setRoles(data.roleList);
              this.setPerfil(data.name)
              this.$router.push('/')
            }
          }).finally(() => this.cancelarLoading());
    },
    cancelarLoading() {
      setTimeout(() => {
        this.isLoading = false;
      }, 1500);
    },
  }
}
</script>

<style scoped>
.form-container[data-v-857fd648] {
    height: 100vh !important;
}
.form-container {
  height: 100vh;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  /*background: linear-gradient(90deg, rgba(249, 249, 249, 0.337) 0%, rgba(103, 103, 103, 0.712) 35%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(135deg, #5EBDB2, #116968);*/
  background: url(../../assets/fondo.jpg);
  background-size: cover;
}

.login-card {
  width: 450px;
  height: 400px;
  margin: auto;
}

.align-container {
  text-align: left;
}

.align-button {
  text-align: right;
}

.form-card {
  margin-top: 20px;
  margin-bottom: 20px;
}
.card{
  box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.466);
  border-radius: 10px;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.card-header {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.label {
  color: hsl(245, 96%, 11%) !important;
  display: block;
  font-size: 0.8rem;
  font-weight: 600;
}</style>