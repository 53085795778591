<template>
    <div class="footer-container">
      <a class="footer-a">
        <img src="../../assets/Infomedia-logo.png" width="70"/>
      </a>
      &nbsp;&nbsp;&nbsp;
      <p>{{ getActualYear() }} &nbsp;&nbsp;<b><a href="http://www.infomediaservice.com"
                                                 style="color: #4A4A4A !important;text-decoration: none;">©
        Infomedia Service S.A. </a></b> v1.0</p>
    </div>
  </template>
  
  <script>
  
  export default {
    name: 'CustomFooter',
    methods: {
      getActualYear() {
        return new Date().getFullYear()
      }
    }
  }
  
  </script>
  
  <style scoped>
  
  .footer-container {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: white;
    border-top: 1px solid lightgray;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  
  .footer-a {
    text-align: center;
  }
  
  </style>